<template>
  <div class="instant-survey-page-editor">
    <a-form :class="device.mobile ? 'py-1' : 'py-5'">
      <a-card
        v-for="(survey, surveyIdx) in formState.contents"
        class="mb-4"
      >
        <a-form-item>
          <span :style="{ fontSize: '1.1rem', fontWeight: '500', marginRight: '12px' }">調查項目 {{ surveyIdx+1 }}.</span>
          <a-tooltip>
            <template #title>刪除</template>
            <MinusCircleOutlined
              v-if="formState.contents.length > 1"
              :disabled="formState.contents.length === 1 || updating"
              @click="removeOneSurvey(surveyIdx)"
            />
          </a-tooltip>
        </a-form-item>

        <a-space
          style="display: flex;"
          align="baseline"
        >
          <a-form-item
            label="欄位名稱"
            :rules="[{ required: true }]"
          >
            <a-input
              v-model:value="survey.name"
              placeholder="例: 血壓"
              :disabled="updating"
            />
          </a-form-item>

          <a-form-item
            label="欄位格式"
            :rules="[{ required: true }]"
          >
            <a-select
              v-model:value="survey.type"
              :options="formContentTypeOptions"
              @change="changeContentType(surveyIdx)"
              :style="{ minWidth: '95px' }"
              :disabled="updating"
            ></a-select>
          </a-form-item>
        </a-space>
        
        <a-row :gutter="12">
          <template v-if="survey.type == 'radio'">
            <a-col :span="24">
              <a-form-item>
                <div
                  class="ml-4 mb-2"
                  v-for="(selectionItem, selectionItemIdx) in survey.selection_items"
                >
                  <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                  <a-input
                    v-model:value="selectionItem.label"
                    :placeholder="'選項 '+(selectionItemIdx+1)"
                    :disabled="updating"
                    :bordered="false"
                    :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                  />
                  <a-tooltip placement="right">
                    <template #title>
                      <span>刪除選項</span>
                    </template>
                    <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteSurveySelectionItem(surveyIdx, selectionItemIdx)">
                      <template #icon>
                        <span class="mdi mdi-close" />
                      </template>
                    </a-button>
                  </a-tooltip>
                </div>

                <div class="ml-4">
                  <span class="mdi mdi-radiobox-blank mr-2" :style="{ fontSize: '1rem' }" />
                  <a-button
                    @click="addSurveySelectionItem(surveyIdx)"
                    type="text" size="small"
                  >新增選項</a-button>
                </div>
              </a-form-item>
            </a-col>
          </template>

          <template v-if="survey.type == 'checkbox'">
            <a-col :span="24">
              <a-form-item>
                <div
                  class="ml-4 mb-2"
                  v-for="(selectionItem, selectionItemIdx) in survey.selection_items"
                >
                  <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                  <a-input
                    v-model:value="selectionItem.label"
                    :placeholder="'選項 '+(selectionItemIdx+1)"
                    :disabled="updating"
                    :bordered="false"
                    :style="{ width: '200px', borderBottom: '1px solid #d9d9d9' }"
                  />
                  <a-tooltip placement="right">
                    <template #title>
                      <span>刪除選項</span>
                    </template>
                    <a-button type="link" shape="circle" :style="{ color: '#757575' }" @click="deleteSurveySelectionItem(surveyIdx, selectionItemIdx)">
                      <template #icon>
                        <span class="mdi mdi-close" />
                      </template>
                    </a-button>
                  </a-tooltip>
                </div>

                <div class="ml-4">
                  <span class="mdi mdi-checkbox-blank-outline mr-2" :style="{ fontSize: '1rem' }" />
                  <a-button
                    @click="addSurveySelectionItem(surveyIdx)"
                    type="text" size="small"
                  >新增選項</a-button>
                </div>
              </a-form-item>
            </a-col>
          </template>

          <template v-if="survey.type == 'slider'">
            <a-col :span="12">
              <a-form-item label="最小值">
                <a-input
                  v-model:value="survey.rule.min"
                  placeholder="例: 1"
                  :disabled="updating"
                />
              </a-form-item>
            </a-col>
            <a-col :span="12">
              <a-form-item label="最大值">
                <a-input
                  v-model:value="survey.rule.max"
                  placeholder="例: 5"
                  :disabled="updating"
                />
              </a-form-item>
            </a-col>
          </template>
        </a-row>
      </a-card>
    </a-form>

    <a-button type="dashed" :disabled="updating" @click="insertOneSurvey">
      <PlusOutlined />
      新增調查項目
    </a-button>

    <div class="mt-10" align="center">
      <a-button
        @click="handleUpdate"
        key="submit"
        type="primary"
        size="large"
        :loading="updating"
        :style="{ width: '120px' }"
      >確認更新</a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { message } from 'ant-design-vue'
import { createDeviceDetector } from 'next-vue-device-detector'
import { MinusCircleOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { useInstantSurveyStore } from './store/index'
import api from '@/apis'
import CopyUtil from '@/utils/CopyUtil'

export default defineComponent({
  components: {
    MinusCircleOutlined,
    PlusOutlined,
    CloseOutlined
  },
  setup() {
    const route = useRoute();
    const device = createDeviceDetector();
    
    /* Store */
    const instantSurveyStore = useInstantSurveyStore();

    /* Loading */
    const updating = ref(false);

    /* Form */
    const formState = ref({ ...instantSurveyStore.data });
    const formSurveyContent = {
      name: '',
      type: 'number',
      rule: {},
      selection_items: [],
      required: false
    }
    const formContentTypeOptions = ref([{ // number|text-field|radio|checkbox|slider
      label: '數字',
      value: 'number'
    }, {
      label: '簡答文字',
      value: 'text-field'
    }, {
      label: '單選題',
      value: 'radio'
    }, {
      label: '多選題',
      value: 'checkbox'
    }, {
      label: '滑動條',
      value: 'slider'
    }]);
    const contentTypeRule = {
      slider: {
        min: 1,
        max: 5
      }
    }
    const changeContentType = (surveyIdx) => {
      const type = formState.value.contents[surveyIdx].type;

      if (type == 'radio') {
        if (!formState.value.contents[surveyIdx].selection_items.length) {
          formState.value.contents[surveyIdx].selection_items.push({
            label: ''
          });
        }
      }
      else if (type == 'checkbox') {
        if (!formState.value.contents[surveyIdx].selection_items.length) {
          formState.value.contents[surveyIdx].selection_items.push({
            label: ''
          });
        }
      }
      else if (type == 'slider') {
        formState.value[selectedKey.value].contents[surveyIdx].rule = { ...contentTypeRule[type] };
      }

      console.log(formState.value.contents);
    }

    // survey selection-items
    const addSurveySelectionItem = (surveyIdx) => {
      formState.value.contents[surveyIdx].selection_items.push({
        label: ''
      });
    }

    const deleteSurveySelectionItem = (surveyIdx, selectionItemIdx) => {
      formState.value.contents[surveyIdx].selection_items.splice(selectionItemIdx, 1);
    }

    /* Api */
    const getInstantSurveyData = (async () => {
      const response = await api.v1.course.findOneInstantSurvey(route.params.course_id, route.params.id);
      instantSurveyStore.setData({ data: response.data.result });
    });

    return {
      device,

      /* Store */
      instantSurveyStore,

      /* Loading */
      updating,

      /* Form */
      formState,
      formSurveyContent,
      formContentTypeOptions,
      contentTypeRule,
      changeContentType,
      // survey selection-items
      addSurveySelectionItem,
      deleteSurveySelectionItem,

      /* Api */
      getInstantSurveyData,

      /* Utils */
      CopyUtil
    }
  },
  methods: {
    async handleUpdate() {
      const err = this._checkRequired();
      if (err) {
        message.error(err);
        return
      }

      this.updating = true;

      try {
        await api.v1.course.updateOneInstantSurvey(this.$route.params.course_id, this.$route.params.id, {
          contents: this.formState.contents
        });
        message.success('更新即時問卷成功');
      } catch (error) {
        console.log(error);
        message.error('更新即時問卷發生錯誤，請稍後再試');
      } finally {
        this.getInstantSurveyData();
        this.updating = false;
      }
    },
    _checkRequired() {
      let err = '';

      const contents = this.formState.contents;
      for (let i=0; i<contents.length; i++) {
        const content = contents[i];
        if (content.name == '') {
          err = `請輸入 調查項目${i+1} 的欄位名稱`;
          break
        }
        if (content.type == '') {
          err = `請輸入 調查項目${i+1} 的欄位格式`;
          break
        }
      }

      return err
    },

    /* Form State */
    insertOneSurvey() {
      this.formState.contents.push(CopyUtil.iterateObjectDeepClone(this.formSurveyContent));
    },
    removeOneSurvey(index) {
      this.formState.contents.splice(index, 1);
    }
  }
})
</script>

<style lang="scss">
.instant-survey-page-editor {
  width: 100%;
  margin: 0 auto;

  /* md */
  @media screen and (min-width: 970px) {
    width: 550px;
  }
}
</style>